document.addEventListener("DOMContentLoaded", function () {
  var calendarEl = document.getElementById("calendar");

  if (!calendarEl) {
    return;
  }

  var calendar = new FullCalendar.Calendar(calendarEl, {
    initialView: "dayGridMonth",
    events: eventCalendarEvents,

    dateClick: function (info) {
      // document.querySelector("#new-closed-date-modal").click();
      let newUrl = "/closed_dates/new.js?start_date="; 

      if( eventCalendarCurrentViewType == 'temporary' ){
        newUrl = "/temporary_location_dates/new.js?start_date="; 
      }

      if (window.newUrlClosedDate != undefined)
        newUrl = window.newUrlClosedDate + "?start_date=";

      let tempLink = document.createElement("a");
      tempLink.setAttribute("data-remote", "true");
      tempLink.href = newUrl + info.dateStr;
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },

    eventClick: function (info) {
      info.jsEvent.preventDefault(); // don't let the browser navigate
      if (info.event.url.endsWith(".js")) {
        let tempLink = document.createElement("a");
        tempLink.setAttribute("data-remote", "true");
        tempLink.href = info.event.url;
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      } else if (info.event.url) {
        window.open(info.event.url);
      }
    },

    eventContent: function (arg) {
      let event = arg.event;
      let description = event.extendedProps.description;
      let type = event.extendedProps.type;

      // Create a custom event content
      let customHtml = '';
        
        if (event.title != '') {

          customHtml += '<div class="fc-event-title" style="padding-left: 5px;">' +
            event.title +
            "</div>";
          customHtml += "<br/>";
        }

      customHtml +=
        '<span class="event-description" style="padding-left: 5px; ' + ( event.title != '' ? '' : 'padding-top: 2px;') + ' ">';

      if (description) {
        customHtml += description;
      }

      if (type) {
        customHtml += (description ? "<br>" : "") + type;
      }

      customHtml += "</span>" + (!description && type ? "<br>" : "");

      return { html: customHtml };
    },
  });

  $(".content-wrapper").removeClass("content-wrapper");
  calendar.render();
});
